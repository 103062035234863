<template>
  <div class="colo-change">
    <transition name="fade" mode="out-in">
      <base-loader v-if="!configurator.length" />
      <main-card v-else>
        <!--        <template v-if="status === 4 || status === 5 || tariff.in_pay" #header>-->
        <!--          <base-alert :title="$t('disabled.title')">-->
        <!--            {{ $t('disabled.text') }}-->
        <!--          </base-alert>-->
        <!--        </template>-->
        <div class="colo-change-config">
          <component
            :is="componentsByType[item.type]"
            v-for="item in configurator"
            :key="item.intname"
            v-bind="item"
            :shown-period="tariff.period_id"
            class="colo-change-config__item"
            @change="onChange(item, $event)"
          />
        </div>
        <!--        <template #footerEnd>-->
        <!--          <div class="rent-change__footer">-->
        <!--            <div v-if="status !== 4 && status !== 5" class="rent-change__summary medium-title">-->
        <!--              <span class="rent-change__summary-text"> {{ $t(`res.${summaryKey}`) }}: </span>-->
        <!--              <span class="rent-change__summary-sum">-->
        <!--                {{ $n(Math.abs(sum), 'currency') }}-->
        <!--              </span>-->
        <!--              <v-popover-->
        <!--                v-if="!!sum"-->
        <!--                placement="top"-->
        <!--                :auto-hide="true"-->
        <!--                popover-class="rent-change__popover"-->
        <!--                class="rent-change__more"-->
        <!--              >-->
        <!--                <plain-button icon="help" class="rent-change__more-btn" />-->
        <!--                &lt;!&ndash;                <config-cost-details&ndash;&gt;-->
        <!--                &lt;!&ndash;                  v-if="detailsData"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  slot="popover"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  v-bind="detailsData"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  class="rent-change__details"&ndash;&gt;-->
        <!--                &lt;!&ndash;                />&ndash;&gt;-->
        <!--              </v-popover>-->
        <!--            </div>-->
        <!--            <div v-else-if="status === 5" class="rent-change__text">-->
        <!--              {{ $t('disabled.note') }}-->
        <!--            </div>-->
        <!--            &lt;!&ndash;            <base-button&ndash;&gt;-->
        <!--            &lt;!&ndash;              :disabled="!hasChanges || status === 4 || status === 5 || tariff.in_pay"&ndash;&gt;-->
        <!--            &lt;!&ndash;              :loading="isSending"&ndash;&gt;-->
        <!--            &lt;!&ndash;              class="rent-change__order-btn"&ndash;&gt;-->
        <!--            &lt;!&ndash;              @click="order()"&ndash;&gt;-->
        <!--            &lt;!&ndash;            >&ndash;&gt;-->
        <!--            &lt;!&ndash;              {{ status === 1 ? $t('save') : $t('order') }}&ndash;&gt;-->
        <!--            &lt;!&ndash;            </base-button>&ndash;&gt;-->
        <!--          </div>-->
        <!--        </template>-->
      </main-card>
    </transition>
  </div>
</template>

<script>
import { ColoTariff } from '@/models/BillMgr/ColoTariff';
import MainCard from '@/components/MainCard/MainCard.vue';
import SliderBlock from '@/components/Configurator/components/SliderBlock.vue';
import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock.vue';
import SelectBlock from '@/components/Configurator/components/SelectBlock.vue';
import TextBlock from '@/components/Configurator/components/TextBlock';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import { debounce } from 'lodash';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'ColoConfig',
  components: { MainCard, SliderBlock, CheckboxBlock, SelectBlock, BaseAlert, TextBlock },
  mixins: [showErrorModal],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof ColoTariff,
    },
  },
  data() {
    return {
      sum: 0,
      loading: false,
      isSending: false,
      componentsByType: {
        slider: SliderBlock,
        // checkbox: CheckboxBlock,
        // select: SelectBlock,
        text: TextBlock,
        textarea: TextBlock,
        // data: TextBlock,
      },
      detailsData: null,
    };
  },
  computed: {
    configurator() {
      return this.tariff.configurator;
    },
    pricelistId() {
      return this.tariff.pricelistId;
    },
    ext() {
      return this.tariff.addonsExt;
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    summaryKey() {
      return this.sum > 0 ? 'pay' : this.sum < 0 ? 'refund' : 'def';
    },
    hasChanges() {
      return !!this.sum;
    },
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
    status() {
      return this.tariff.status.code;
    },
  },
  watch: {
    configurator() {
      if (this.configurator.length) this.calcSum();
    },
    pricelistId(val) {
      if (val) this.fetchExt(val);
    },
  },
  mounted() {
    if (this.pricelistId && !this.ext) this.fetchExt(this.pricelistId);
    if (this.configurator.length) this.calcSum();
  },
  methods: {
    onChange(item, payload) {
      const { value, sum } = payload;
      item.currentValue = value;
      if (item.sum !== sum) {
        item.sum = sum;
        this.calcSum();
      }
    },
    calcSumOld() {
      this.sum = this.configurator.reduce((sum, i) => {
        sum += i.sum;
        return sum;
      }, 0);
    },
    getConfData() {
      return this.configurator.reduce((acc, addon) => {
        acc[addon.name] = addon.currentValue;
        return acc;
      }, {});
    },
    calcSum: debounce(function () {
      const data = this.getConfData();
      if (!this.loading) {
        this.$store
          .dispatch('moduleVPS/fetchTariffCalc', {
            ...data,
            elid: this.tariff.id,
            account: this.profile.account,
          })
          .then(data => {
            if (data && data.model && data.model.cost) {
              this.sum = parseFloat(data.model.cost);
              this.detailsData = {
                details: data.model.cost_details,
                total: this.sum,
                expireDate: new Date(data.model.expiredate),
                period: this.tariff.period_id,
              };
            }
          })
          .catch(e => console.error(e))
          .finally(() => (this.loading = false));
      }
    }, 500),
    fetchExt(id) {
      return this.$store.dispatch('moduleVPS/fetchAddons', id);
    },
    order() {
      const data = this.getConfData();
      this.isSending = true;
      this.$store
        .dispatch('moduleVPS/updateDetail', {
          ...data,
          elid: this.tariff.id,
          account: this.profile.account,
          func: this.tools.edit.func,
        })
        .then(data => {
          if (data.ok) {
            return Promise.all([
              this.$store.dispatch('moduleVPS/fetchDetail', { id: this.tariff.id }),
              this.$store.dispatch('moduleProfile/setProfileInfo'),
            ])
              .then(() => this.fetchExt(this.pricelistId))
              .then(() => setTimeout(() => (this.isSending = false), 500));
          }
        })
        .catch(e => {
          this.showError(e);
          this.isSending = false;
        });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "res": {
      "def": "Итого",
      "pay": "К оплате",
      "refund": "Вернём"
    },
    "order": "Заказать",
    "save": "Сохранить",
    "disabled": {
      "title": "Изменение параметров недоступно",
      "text": "Виртуальный сервер находится в процессе обработки.",
      "note": "Дождитесь завершения обработки"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.colo-change {
  margin-top: 1.5rem;

  +breakpoint(sm-and-up) {
    margin-top: 2.5rem;
  }

  &__footer {
    flexy(flex-end, flex-end);
    flex-direction: column;

    +breakpoint(sm-and-up) {
      flex-direction: row;
      align-items: center;
      padding-bottom: 1rem;
    }
  }

  &__summary {
    flexy(flex-end, center);
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 2.5rem;
    }

    &-text {
      margin-right: 1.5rem;
    }
    &-sum {
      text-transform: lowercase;
    }
  }

  &__text {
    margin-bottom: 1rem;
    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 2.5rem;
    }
  }

  &__order-btn {
    min-width: 160px;
  }

  &-config {
    +breakpoint(sm-and-up) {
      margin-top: 1rem;
    }

    &__item {
      & + & {
        margin-top: 1.5rem;

        +breakpoint(sm-and-up) {
          margin-top: 2.5rem;
        }
      }
    }
  }

  &__more {
    margin-left: 1rem;

    &-btn {
      color: $color-light.medium;
      opacity: 0.5;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }
  &__details {
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: auto;
      max-width: calc(100vw - 3.25rem);
    }
  }
}
</style>
